body {
  background: #f3f3f3;
}

.heading {
  max-width: 1600px;
  margin: 0 auto;
  padding: 40px;
}

.heading a {
  max-width: 1600px;
  margin: 0 auto;
  font-weight: medium;
  text-decoration: none;
  color: #000;
  cursor: alias;
}

.heading a:hover {
  border-bottom: 1px solid #0142c4;
  color: #0142c4;
}

.main {
  max-width: 1600px;
  margin: 0 auto;
  padding: 16px 40px 64px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  row-gap: 4rem;
  column-gap: 2rem;
  align-items: center;
  justify-items: center;
}

a.image {
  position: relative;
}

img.image {
  max-width: 100%;
  max-height: 100%;
  align-self: center;
}
